import React from 'react'
import { useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState, useEffect, useRef } from 'react';
import { setAuthToken } from '../Compontents/setAuthToken';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import VideocamIcon from '@mui/icons-material/Videocam';
import { Avatar, IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import CloseIcon from '@mui/icons-material/Close';
import io from 'socket.io-client'
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import SendIcon from '@mui/icons-material/Send';
import MicIcon from '@mui/icons-material/Mic';
import { Refresh } from '@mui/icons-material';
// const TextArea = styled.textarea`

// `;
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));
const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '--TextField-brandBorderColor': 'lightgrey',
            '--TextField-brandBorderHoverColor': 'blue',
            '--TextField-brandBorderFocusedColor': 'white',
            '& label.Mui-focused': {
              color: 'var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: 'var(--TextField-brandBorderColor)',
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderHoverColor)',
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            '&:before, &:after': {
              borderBottom: '2px solid var(--TextField-brandBorderColor)',
            },
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
            },
            '&.Mui-focused:after': {
              borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '&:before': {
              borderBottom: '2px solid var(--TextField-brandBorderColor)',
            },
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
            },
            '&.Mui-focused:after': {
              borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
    },
  });
function Chat() {
  let { id, pid } = useParams()
  let navigate = useNavigate()
  let [count, setCount] = useState(0)
  let Token = sessionStorage.getItem('AccessToken')
  let [appointments, setappointments] = useState({})
  let [messages, setmessages] = useState([])
  const [online, setOnline] = useState([])
  let [arrivalMessage, setArrivalMessage] = useState(null);
  const socket = useRef();
  let user = sessionStorage.getItem('User_details')
  let userdetails = JSON.parse(user)
  let [Searchbar, setSearchbar] = useState(true)
  let [Search, setSearch] = useState('')
  let [msg, setmsg] = useState('')
  const textAreaRef = useRef(null);
  let scrollRef = useRef();
  const outerTheme = useTheme();

  const UID=sessionStorage.getItem('Doctorid')

  let Collectmsgs = async () => {
    try {
      const res = await axios.post('https://api.ayurai.in/api/data/getMessages', {
        from: UID,
        to: pid,
      });

      if (res.status === 200) {
        setmessages(res.data);
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    Collectmsgs()

 }, [msg]);
  console.log(socket)
  useEffect(() => {
    socket.current = io('https://api.ayurai.in');
    socket.current.emit("add-user",UID);
    socket.current.on("get-users", (users) => {
      setOnline(users)
    });

  }, [])

  const sendchat = () => {
    socket.current.emit("send-msg", {
      to: pid,
      from:UID,
      from_name: appointments?.Data?.doctor_name,
      to_name: appointments?.Data?.patient_id,
      type: "text",
      timestamp: Date.now(),
      message: msg,
    });
    setmsg('')
  }

  const resizeTextArea = () => {
    textAreaRef.current.style.height = "20px";
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
  };

  useEffect(resizeTextArea,
    [msg]);

  useEffect(() => {
    if (socket.current) {
      socket.current.on("msg-recieve", (msg) => {
        setArrivalMessage(msg);
      });
    }
  }, []);

  useEffect(() => {
    arrivalMessage && setmessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

 
  const getApppointments = async () => {
    try {
      setAuthToken(Token)
      let res = await axios.post('https://api.ayurai.in/api/data/getOneAppointmentsDoctors', {
        PID:pid,
        appointmentID: id
      })

      if (res.status === 200) {
        setappointments(res.data)
      }
    }
    catch (error) {
      if (error?.response?.data?.error.message === 'jwt expired' || error?.status === 500) {
        renew()
      }
    }
  }


  let renew = async () => {
    try {

      let refreshtoken=sessionStorage.getItem('refreshToken')
      let res = await axios.post(`https://api.ayurai.in/auth/renew`, {
        email: userdetails.Email,
        token: refreshtoken
      })

      if (res.status === 200) {
        let data = res.data
        sessionStorage.setItem('AccessToken', data.access_token)
        setCount(count + 1)

      }
    }
    catch (error) {
      // console.log(error,'ll')
    }
  }


  useEffect(() => {
    getApppointments()
  }, [])

  return <div className='main'>
    <div className='containes'>
      <div className='chat-section-header'>

      </div>
      <div className='chat-box'>
        <div className='chat-head'>
          <ArrowBackIcon onClick={() => {
            navigate(-1)
          }} />
          {Searchbar ? <>
            {online.find((id) => id.userId === pid) ?
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
              >
                <Avatar alt="Remy Sharp"  />
              </StyledBadge> :

              <Avatar  style={{ objectPosition: "top" }}></Avatar>
            }
            <div className='chat-head-info' >
              <h3>{appointments?.Data?.patient_name}</h3>
              <h6 style={{ fontSize: "13px" }}>{online.find((id) => id.userId === pid) ? "Online" : <></>}</h6>
            </div>
            <div className='Chat-head-right'>
              <IconButton>{
                <VideocamIcon style={{ color: "white" }}></VideocamIcon>
              }</IconButton>
              <IconButton onClick={() => {
                setSearchbar(!Searchbar)
              }} style={{ color: "white" }}><SearchIcon></SearchIcon></IconButton>
            </div>
          </> : <><ThemeProvider theme={customTheme(outerTheme)}><TextField label="Search messages" fullWidth variant="standard" inputProps={{ style: { color: "whitesmoke" } }} onChange={(e) => { setSearch(e.target.value) }}
          /></ThemeProvider>
            <IconButton onClick={() => {
              setSearchbar(!Searchbar)
            }}><CloseIcon style={{ color: "white" }}></CloseIcon></IconButton></>
          }
        </div>
        <div className='chat-body'>
          {
            messages?.filter((s) => {
              if (Search == '') {
                return s
              }
              else if (s.message.toLowerCase().includes(Search.toLowerCase())) {
                return s
              }
            }).map((e, i) => {
              return <div
                ref={scrollRef}
                className={e.sender === UID ? "message own" : "message"} key={i}
              >
                <span>{e.message.includes('https') ? <a href={e.message} target='_blank'>{e.message}</a> : e.message}</span>
              </div>
            })
          }
        </div>
        <div className='chat-footer'>
          <textarea ref={textAreaRef} placeholder="Message..." value={msg} style={{color:"black"}} type=
          'text' onChange={(e) => { setmsg(e.target.value) }} row="1" />&nbsp;
          {msg?.length > 0 ? <>
            {appointments.Data.appointment_status!='completed'&&<div className='sendbtn' type="submit" onClick={(e) => sendchat(e)}
            ><SendIcon /></div>}

          </> :
            <div className='sendbtn'>
              <MicIcon />
            </div>}
        </div>
      </div>
    </div>
  </div>

}
export default Chat