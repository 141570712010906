import React from 'react'
import { DialogTitle, IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useEffect, useContext } from 'react';
// import 'react-modern-calendar-datepicker/lib/DatePicker.css';
// import { Calendar, utils } from "react-modern-calendar-datepicker";
import { isSunday, isSaturday } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import noappointment from '../Compontents/animation_ln495gdl.json'
import axios from 'axios';
import { setAuthToken } from '../Compontents/setAuthToken';
import Lottie from 'lottie-react';
import { toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { contextAPI } from './Context';
function BookAppointment() {
    let navigate = useNavigate()
    const [selectedDay, setSelectedDay] = useState(null);
    const [formattedDay, setformated] = useState('')
    let refreshtoken = sessionStorage.getItem('refreshToken')
    let user = sessionStorage.getItem('User_details')
    let userdetails = JSON.parse(user)
    let { id } = useParams()
    let [count, setCount] = useState(0)
    let data = useContext(contextAPI)
    let [open, setOpen] = useState(false);
    let [checked, setchecked] = useState(false)

    const [DateNTime, setDateNTime] = useState({
        Date: "",
        Time: ""
    })

    let today = new Date()

    let Bookeddays = data.DoctorAppointment

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const handleSubmit = () => {
        if (!DateNTime.Date) {
            toast.info('Please select a date', {
                position: "top-center",
                limit: 1,
                transition: Zoom,
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
            })
        }

        if (!DateNTime.Time) {
            toast.info('Please select a time', {
                position: "top-center",
                limit: 1,
                transition: Zoom,
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
            })
        }
        else if (DateNTime.Date && DateNTime.Time) {
            handleClickOpen()
        }
    }
    const RescheduleAppointment = async () => {
        try {
            let Token = sessionStorage.getItem('AccessToken')
            setAuthToken(Token)
            let AppointmentStatus = await axios.post('https://api.ayurai.in/api/data/updateAppointmentForRescheduleDoctors', {
                UID:alldatadetails.UID,
                updatedTime:DateNTime.Time,
                updatedDate:DateNTime.Date,
                appointmentID: id
            })

            if (AppointmentStatus.status === 200) {
                navigate(-1)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const handledate = (date) => {
        setSelectedDay(date)
        const inputDate = new Date(date);
        const day = inputDate.getDate();
        const month = inputDate.toLocaleString('default', { month: 'short' });
        const year = inputDate.getFullYear();
        const formattedDate = `${inputDate.toLocaleDateString('en-US', { weekday: 'short' })}, ${day} ${month} ${year}`;
        setformated(formattedDate)
    }

    


    let renew = async () => {
        try {

            let refreshtoken = sessionStorage.getItem('refreshToken')
            let res = await axios.post(`https://api.ayurai.in/auth/renew`, {
                email: userdetails.Email,
                token: refreshtoken
            })

            if (res.status === 200) {
                let data = res.data
                sessionStorage.setItem('AccessToken', data.access_token)
                setCount(count + 1)

            }
        }
        catch (error) {
            // console.log(error,'ll')
        }
    }

    const findBookedAppointments = Bookeddays.filter((e) => {
        if (e?.Data?.appointment_date === formattedDay && e?.Data?.appointment_status != 'cancelled') {
            return e
        }
    }).map((e) => {
        return e?.Data?.appointment_time
    })


    let patientdetails = data.DoctorAppointment.filter((e) => {
        if (e.Data.appointmentID === id) {
            return e
        }
    }).map((e) => { return e })

    let alldatadetails = patientdetails[0]



    useEffect(() => {
        handledate(new Date())
    }, [])


    const consultationmodes = [{
        Mode: 'Phone',
        Price: "200",
        actualPrice: "300"
    }, {
        Mode: 'Video',
        Price: "300",
        actualPrice: "400"
    }]

    const modifiers = {
        saturday: (date) => isSaturday(date),
        sunday: (date) => isSunday(date),
    };



    const dayPickerProps = {
        mode: "single",
        selected: selectedDay,
        onSelect: (date) => {
            handledate(date);
        },
        disabled: (date) => date <= new Date(),
        modifiers,
        modifiersStyles: {
            saturday: { color: "red" },
            sunday: { color: "red" },
        },

    };
    if (!Bookeddays) {
        return <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}

        >
            <CircularProgress color="inherit" />
        </Backdrop>
    }

    return <>
        <div className='Cart-header'>
            <IconButton onClick={() => {
                navigate(-1)
            }}>
                <ArrowBackIcon />
            </IconButton>
        </div>

        <div className='BookAppointment-page'>
            <h4>Choose a date & time period for consultation</h4>
            <div className='Datepick'>
                <DayPicker
                    {...dayPickerProps}
                />
                {
                    formattedDay.split(',').includes('Sat') || formattedDay.split(',').includes("Sun") ?
                        <div className='slotsunavailable'>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p>Slots Unavailable</p>
                                <Lottie animationData={noappointment} style={{ width: '8rem' }}></Lottie>
                            </div>
                            <p>Sorry for the inconvenience caused. But the Doctor has no slots left for the day</p>
                        </div>
                        : <div>
                            <p id='timings'>Timings</p>
                            <div className='timings'>
                                {

                                    data?.Doctors?.Data?.timing.split(',').filter((e) => {


                                        const inputTimeStr = data?.Dateandtime?.time || '2:00 pm';
                                        const timeRegex = /^(\d{1,2}:\d{2} [apAP][mM])$/;

                                        if (!timeRegex.test(inputTimeStr)) {
                                            console.log("Invalid input time format. Please use the format 'hh:mm am/pm'.");
                                        } else {
                                            const currentTime = new Date().toLocaleTimeString('en-US', { hour12: true });
                                            function convertTo24Hour(timeStr) {
                                                const [time, period] = timeStr.split(' ');
                                                const [hours, minutes] = time.split(':');
                                                let hours24 = parseInt(hours);
                                                if (period.toLowerCase() === 'pm' && hours24 !== 12) {
                                                    hours24 += 12;
                                                }
                                                return `${hours24.toString().padStart(2, '0')}:${minutes}`;
                                            }


                                            const inputTime = convertTo24Hour(e);
                                            const currentTime24 = convertTo24Hour(currentTime);
                                            const Today = new Date().getDate()
                                            const Month = new Date().getMonth()
                                            const Fullyear = new Date().getFullYear()

                                            const selectedday = new Date(selectedDay).getDate()
                                            const selctedmonth = new Date(selectedDay).getMonth()
                                            const selectedyear = new Date(selectedDay).getFullYear()

                                            if (parseInt(Today) === parseInt(selectedday) && parseInt(Month + 1) === parseInt(selctedmonth + 1) && parseInt(Fullyear) === parseInt(selectedyear)) {
                                                if (currentTime24 < inputTime) {
                                                    return e
                                                }
                                            }
                                            else {
                                                return e
                                            }
                                        }
                                    }).map((e, i) => {
                                        return <div className='slots mb-0' key={i} style={DateNTime.Date === formattedDay && DateNTime.Time === e ? { backgroundColor: "#0d8aed" } : findBookedAppointments?.find((s) => {
                                            if (s === e) {
                                                return true
                                            }
                                        }) ? { backgroundColor: "#90EE90" } : {}}>
                                            <p className='mb-0' style={DateNTime.Date === formattedDay && DateNTime.Time === e || findBookedAppointments?.find((s) => {
                                                if (s === e) {
                                                    return true
                                                }
                                            }) ? { color: "white" } : { color: "black" }} onClick={() => {
                                                if (findBookedAppointments?.find((s) => {
                                                    if (s === e) {
                                                        return true
                                                    }
                                                })) {
                                                    toast.info('Slot is already Booked', {
                                                        position: "top-center",
                                                        limit: 1,
                                                        transition: Zoom,
                                                        autoClose: 2000,
                                                        hideProgressBar: false,
                                                        closeOnClick: false,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                        theme: "dark"
                                                    })
                                                }
                                                else {
                                                    setDateNTime({ Date: formattedDay, Time: e })
                                                }
                                            }}>{e}</p>

                                            {findBookedAppointments?.find((s) => {
                                                if (s === e) {
                                                    return true
                                                }
                                            }) ? <p className='mb-0'>Booked</p> : <p className='mb-0'>Free</p>}
                                        </div>
                                    })
                                }
                            </div>

                        </div>}

                {/* </div> */}
            </div>
            <div className='current_Schedule'>
                <h1>
                    Current Schedule
                </h1>
                <div>
                    <p>Date : <span>{alldatadetails.Data.appointment_date}</span></p>
                    <p>Time : <span>{alldatadetails.Data.appointment_time}</span></p>
                </div>
            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className='ctTitle'>Confirm Reschedule</DialogTitle>
                <DialogContent>
                    <div className='current_Schedule'>
                        <h1>
                            Current Schedule
                        </h1>
                        <div>
                            <p>Date : <span>{alldatadetails.Data.appointment_date}</span></p>
                            <p>Time : <span>{alldatadetails.Data.appointment_time}</span></p>
                        </div>
                    </div>
                   < div className='current_Schedule'>
                        <h1 style={{backgroundColor:"lightcyan"}}>
                            Updated Schedule
                        </h1>
                        <div>
                            <p>Date : <span>{DateNTime.Date}</span></p>
                            <p>Time : <span>{DateNTime.Time}</span></p>
                        </div>
                    </div>

                </DialogContent>
                <div className='disclaimer'>
                    <p>Disclaimer : You can only reschedule once</p>
                </div>
                <DialogActions>
                    <Button onClick={RescheduleAppointment} variant='outlined'>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
        <div className='bookappointmentbtn'>
            <Button style={{ backgroundColor: '#0076BE', color: "white" }} id='bookappointmentbtn' onClick={() => {
                handleSubmit()
            }}>Reschedule</Button>
        </div>
    </>
}

export default BookAppointment
