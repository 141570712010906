import React from 'react'
import { useState, useRef, useMemo, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DialogContentText from '@mui/material/DialogContentText';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Slide from '@mui/material/Slide';

import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import { Buffer } from "buffer";
import { toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Patient from './Patient';
Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;
function Reports() {
    const navigate = useNavigate()
    const {id,doc_id}=useParams()
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [listReports, setlistReports] = useState([])
    const [Allreports, setReports] = useState([])
    const [reporttype, setReportype] = useState('')
    const [count, setcount] = useState(0)
    const [selectedOption,setSelectedOption]=useState('')
    const [uploadProgress, setUploadProgress] = useState(0);
    let doctorid=sessionStorage.getItem('Doctorid')
    const data = JSON.parse(localStorage.getItem('Userdetails'))
    const fileInput = useRef();
    const [filetype,setfiletype]=useState('')

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const options = ['MRI/CI Report', 'X-Ray', 'USG Report', 'ECG Report','Others']

    function convertToBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                const base64String = fileReader.result
                    .replace('data:', '')
                    .replace(/^.+,/, '');
                    resolve(base64String)
            };
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }


    let handleFileUpload = async (e) => {
        e.preventDefault();
        let reportsArray = fileInput.current.files;
        for (let i = 0; i < reportsArray.length; i++) {
            let file = reportsArray[i]
            let filedata = await convertToBase64(file);
            setReports((prev) => [...prev, {
                file: filedata, fileName: file?.name
            }])
        }


    }

    const uploadtos3 = async () => {
        if (reporttype != '') {
            try {
                let res = await axios.post('https://api.ayurai.in/api/data/uploadreports', {
                    UID: id,
                    reports: Allreports,
                    ReportId: Date.now(),
                    ReportUploadedby:doctorid,
                    ReportType: reporttype,
                    CreatedAt: new Date(),
                    filetype
                },{
                    onUploadProgress: (progressEvent) => {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        setUploadProgress(progress);
                    }
                })
                if (res.status === 200) {
                    setReports([])
                    setcount(count + 1)
                    setReportype('')
                    setfiletype('')
                    handleClose2()
                }
            }
            catch (error) {
              if(error.response.status===413){
                console.log(error.response.data)
              }
            }
        } else {
            toast.info("Please Select Report Type", {
                position: "top-center",
                limit: 1,
                transition: Zoom,
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
            })
        }
    }




    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpen2 = () => {
        setOpen2(true);
        setOpen(false)
    };

    const handleClose2 = () => {
        setOpen2(false);
        setOpen(false)
    };

    const handleInputChange = useMemo(() => {
        return (event) => {
            event.preventDefault()
            setSelectedOption(event.target.value)
        };
    }, [reporttype]);

    const handleAutocompleteChange = (event, newValue) => {
        event.preventDefault()
        setReportype(newValue);
        setSelectedOption(newValue)
    };

    const getReports = async () => {
        try {
            let res = await axios.post('https://api.ayurai.in/api/data/Getreports', {
                UID: id,
            })
            if (res.status === 200) {
                setlistReports(res.data)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const deleteReports = async (id) => {
        try {
            let res = await axios.delete(`https://api.ayurai.in/api/data/DeleteOneReport/${id}`)

            if (res.status === 200) {
                setcount(count + 1)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getReports()
    }, [count])

    return <>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Select file type"}
            </DialogTitle>
            <DialogContent>
                <p id='filetypes' onClick={() => {
                    setfiletype('images')
                    handleClickOpen2()
                }}><InsertPhotoIcon style={{ color: "grey" }}
                    />  Images</p>
                <Divider />

                <p id='filetypes' onClick={() => {
                    setfiletype('PDF')
                    handleClickOpen2()
                }}>

                <PictureAsPdfIcon style={{ color: "grey" }} />
                 PDF
                 
                 </p>

            </DialogContent>
        </Dialog>

        <Dialog
            open={open2}
            keepMounted
            onClose={handleClose2}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id='DialogTitle'>{"Upload Report"}</DialogTitle>
            <DialogContent className='uploadreport'>

                <div className='upload_report'>
                    <Button variant="contained" component="label">
                        {filetype === 'images' ? 'Select Multiple Images' : "Select Pdf file"}
                        {filetype === 'images' ? <input hidden type='file' ref={fileInput} multiple onChange={(e) => handleFileUpload(e)} /> :
                            <input hidden type='file' ref={fileInput} accept='.pdf' onChange={(e) => handleFileUpload(e)} />}
                    </Button>
                </div>

                <div >
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={options}
                        onChange={handleAutocompleteChange}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} value={params} label="Select Report Type" onInput={handleInputChange} />}
                    />
                    {
                        selectedOption === 'Others' && <TextField label="Enter Report name" onChange={(e) => { setReportype(e.target.value) }} sx={{ width: 300, marginTop: '1rem' }}></TextField>
                    }
                </div>
                {/* <p id='impinfo'>Please select file size lesser than 2mb</p> */}
                <div className='reportspreview'>
                    {Allreports.map((e) => {
                        return (
                            <>
                                {filetype === 'images' ? <div className='reportpreviewitem'>
                                    <img src={'https://aiwellassets.s3.ap-south-1.amazonaws.com/Image.png'} alt={e.alt} />
                                    <p>{e.fileName}</p>
                                </div> : <div className='reportpreviewitem'>
                                    <img src={'https://aiwellassets.s3.ap-south-1.amazonaws.com/Pdf.png'} alt={e.alt} />
                                    <p>{e.fileName}</p>
                                </div>}
                            </>
                        );
                    })}
                </div>


                {/* Progress bar */}
                {uploadProgress > 0 && (
                    <div style={{ marginTop: '20px', width: '100%', backgroundColor: '#ddd' }}>
                        <div
                            style={{
                                height: '24px',
                                width: `${uploadProgress}%`,
                                backgroundColor: 'blue',
                                transition: 'width 0.2s',
                                textAlign: 'center',
                                color: 'white',
                            }}
                        >
                            {uploadProgress}%
                        </div>
                    </div>
                )}

            </DialogContent>
            <DialogActions>
            <Button style={{ backgroundColor: "#007BFF", color: "white" }} onClick={() => {
                    if (Allreports.length === 0) {
                        toast.info("Select at least one file to upload", {
                            position: "top-center",
                            limit: 1,
                            transition: Zoom,
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark"
                        });
                    } else {
                        uploadtos3();
                    }
                }}
                >
                    Next
                </Button>
            </DialogActions>
        </Dialog>

        <div className='P-page'>
            <IconButton onClick={() => {
                navigate(-1)
            }}>
                <ArrowBackIcon />
            </IconButton>

        </div>
        <div className='Reports-Sections'>
            <p>Stored Reports</p>
            <div className='listofreports'>
                {listReports.sort((a, b) => new Date(b?.DATA?.CreatedAt) - new Date(a?.DATA?.CreatedAt)).map((e) => {
                    return <div className='reportcard' onClick={() => {
                        navigate(`/ViewReports/${e?._id}`)
                    }}>
                        <div>
                            <p className='mb-0'>{e?.DATA?.CreatedAt}</p>
                            <p className='mb-0'>{e?.DATA?.ReportType}</p>
                            <p className='mb-0'>{e?.DATA?.ReportUploadedby === id ? "Uploaded by Patient" : "Uploaded by Doctor"}</p>
                        </div>
                        {e?.DATA?.ReportUploadedby === doctorid && <IconButton onClick={(event) => {
                            event.stopPropagation()
                            deleteReports(e?._id)
                        }}>
                            <DeleteIcon></DeleteIcon>
                        </IconButton>}
                    </div>
                })}
            </div>
            <div className='addrprtbtn'><Button style={{ color: "white", padding: "8px 3rem 8px 3rem", width: "100%" }} onClick={handleClickOpen}>ADD REport</Button></div>
        </div>

    </>
}

export default Reports