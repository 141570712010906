import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const config = {
  apiKey: "AIzaSyCZaCxWZYfbjT7dZ4hXfQSnoh6sH2cPbtw",
  authDomain: "aiwell.firebaseapp.com",
  databaseURL: "https://aiwell-default-rtdb.firebaseio.com",
  projectId: "aiwell",
  storageBucket: "aiwell.appspot.com",
  messagingSenderId: "104644967506",
  appId: "1:104644967506:web:54ffd5f27b6a431702f38b",
  measurementId: "G-8HZEMQXQ8H"
}

const app = initializeApp(config);



export const db = getDatabase(app);