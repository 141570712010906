import React from 'react'
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button'
import { contextAPI } from './Context';
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import { toast,Zoom} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setAuthToken } from './setAuthToken';
function Login() {
    const [showPassword, setShowPassword] = React.useState(false);
    const [email,setEmail]=React.useState('')
    const [password,setPassword]=React.useState('')
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    let data = React.useContext(contextAPI)
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    
    const Navigate=useNavigate()
    
    let handleProfile=async()=>{
      try{
        let doctorid=sessionStorage.getItem('Doctorid')
        let res=await axios.post('https://api.ayurai.in/api/data/getDoctor',{
           UID:doctorid
        })

        if(res.status===200){
          let id=res.data
          if(id){
            data.getDoctors(id)
            sessionStorage.setItem('Ayurdocid',id.Data.PID)
            Navigate('/')
          }
          else{
            toast.info("You Don't have Access", {
              position: "top-center",
              limit: 1,
              transition: Zoom,
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark"
            })
          }
        }
      }
      catch(error){
      
          // toast.info(error.response.data.message, {
          //   position: "top-center",
          //   limit: 1,
          //   transition: Zoom,
          //   autoClose: 2000,
          //   hideProgressBar: false,
          //   closeOnClick: false,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "dark"
          // })
          // console.log(error.response.data.message)
        console.log(error)
  }}

    let handleLogin=async()=>{
        try{
          let res=await axios.post('https://api.ayurai.in/auth/login',{email,password})
          if(res.status===200){
               sessionStorage.setItem("AccessToken",res.data.accesToken)
               sessionStorage.setItem("refreshToken",res.data.refreshToken)
               sessionStorage.setItem('Doctorid',res.data.id)
               sessionStorage.setItem("User_details",JSON.stringify({Name:res.data.name,Email:email}))
               let Token = sessionStorage.getItem('AccessToken')
               setAuthToken(Token)
               console.log(res.data)
               handleProfile()
              }
        }
        catch(error){
            if (error.response.status === 400) {
                toast.error(error.response.data.message, {
                  position: "top-center",
                  limit: 1,
                  transition: Zoom,
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark"
                })
                console.log(error.response.data.message)
              }
        }
    }
  return <>
  <div className='Login-page'>
  <div className='page-left-side'>
    <img src='7317079-removebg-preview.png' className='animation' />
    <img src="ayurailogofull.png" className="logo-img" alt="Logo" />
    </div>
    <div className='page-right-side'>
        <div id='Signin'>
            <p>Sign in</p>
            <TextField sx={{ m: 1, width: '30ch' }} id="outlined-basic" label="Email" variant="outlined" onChange={(e)=>setEmail(e.target.value)}/>
            <FormControl sx={{ m: 1, width: '30ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
            onChange={(e)=>setPassword(e.target.value)}
          />
        </FormControl>
        <Button variant="contained" className="Login-btn" onClick={handleLogin}>Login</Button>
        </div>
    </div>
    </div>
  </>
}

export default Login