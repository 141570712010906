import React, { useEffect } from 'react'
import SideBar from './SideBar'
import Table from '@mui/material/Table';
import { useState, useContext } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { SearchOutlined } from '@mui/icons-material'
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter'
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import axios from 'axios';
function CaseStudy() {
    const [CaseStudyData, setCaseStudyData] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [Search, setsearch] = useState('')
    const [about, setAbout] = useState('')
    const [open, setOpen] = useState(false);

   const getCasedetails=async()=>{
    try{
        let res=await axios.get('https://api.ayurai.in/api/data/getAllCaseSheet')
        if(res.status===200){
            setCaseStudyData(res.data)
        }
    }
    catch(error){
       console.log(error)
    }
   }
    const handleClickOpen = (id) => {
        setAbout(id)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    function BootstrapDialogTitle(props) {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    }
    
    let Navigate = useNavigate()

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(()=>{
     getCasedetails()
    },[])

    return <>
        <SideBar />
        <div className="p-4 sm:ml-64">
            <div className="p-4 rounded-lg dark:border-gray-700 mt-14">
                <div className='Main-contents'>
                    <div >
                    <h1 className='Order_title'>Case Study</h1>
                <div className='Search-bar'>
                    <div className='search-box'>
                        <SearchOutlined />
                        <input type="text" placeholder="Search" onChange={(e) => { setsearch(e.target.value) }} />

                    </div>
                </div>
                <TableContainer sx={{ maxHeight: 440 }} className='Table mt-5' >
                    <Table stickyHeader aria-label="sticky table" >
                        <TableHead>
                            <TableRow>

                                <TableCell className='Table-header'
                                    style={{ minWidth: "100px" }}

                                >
                                  Name
                                </TableCell>
                                <TableCell className='Table-header'
                                    align={"center"}
                                    style={{ minWidth: "100px" }}

                                >
                                  Age
                                </TableCell>
                                <TableCell className='Table-header'
                                    align={"center"}
                                    style={{ minWidth: "100px" }}

                                >
                                   Gender
                                </TableCell>
                                <TableCell className='Table-header'
                                    align={"center"}
                                    style={{ minWidth: "100px" }}

                                >
                                    Height
                                </TableCell>
                                <TableCell className='Table-header'
                                    align={"center"}
                                    style={{ minWidth: "100px" }}

                                >
                                    Weight
                                </TableCell>
                                <TableCell className='Table-header'
                                    align={"center"}
                                    style={{ minWidth: "100px" }}

                                >
                                    More info
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {CaseStudyData
                           
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).filter((s) => {
                                    if (Search === "") {
                                        return s;
                                    }
                                    else if (s.PatientDetails.Name.toLowerCase().includes(Search.toLowerCase())||s.PatientDetails.Gender.toLowerCase().includes(Search.toLowerCase())) {
                                        return s;
                                    }
                                })
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                                            <TableCell >
                                                {row.PatientDetails.Name}
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                {row.PatientDetails.Age}
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                {row.PatientDetails.Gender}
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                {row.PatientDetails.Height}
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                {row.PatientDetails.Weight}
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                <Button variant="outlined" onClick={() =>Navigate(`/UserCaseStudy/${row._id}`)}>
                                                    view   
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    <TableFooter align="right">
                        <TablePagination

                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={CaseStudyData.length}

                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableFooter>
                </TableContainer>
                <div style={{margin:"1rem"}}>
                    <Button variant='outlined' onClick={()=>{
                        Navigate('/CaseSheet')
                    }}>Add CaseSheet</Button>
                </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default CaseStudy